var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"mb-2 d-flex justify-content-between"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.onlyArchived),callback:function ($$v) {_vm.onlyArchived=$$v},expression:"onlyArchived"}},[_vm._v("\n                Archived Only\n            ")]),_c('retention-report')],1),_c('awesome-table',{attrs:{"id":"customers","labels":_vm.labels,"items":_vm.customers,"options":{
                addButton: false,
                sort: true,
                filters: true,
                isCard: false,
                columnsSelector: true
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters},on:{"row-click":function($event){return _vm.$router.push(("/customers/" + ($event.id) + "/orders"))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/customers/" + ($event.id) + "/orders"))},"pagination-change":_vm.onPaginationChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.actions",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.archiveOrRestoreCustomer(item, index)}}},[(!_vm.onlyArchived)?_c('i',{staticClass:"far fa-trash-alt"}):_c('i',{staticClass:"fas fa-trash-restore"})])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }