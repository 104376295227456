<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <div class="mb-2 d-flex justify-content-between">
                <b-form-checkbox v-model="onlyArchived" switch>
                    Archived Only
                </b-form-checkbox>

                <retention-report />
            </div>

            <awesome-table
                id="customers"
                :labels="labels"
                :items="customers"
                :options="{
                    addButton: false,
                    sort: true,
                    filters: true,
                    isCard: false,
                    columnsSelector: true
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                @row-click="$router.push(`/customers/${$event.id}/orders`)"
                @mouse-wheel-click="$openNewTab(`/customers/${$event.id}/orders`)"
                @pagination-change="onPaginationChange"
                @filter-change="onFilterChange"
            >
                <template #[`items.actions`]="{ item, index }">
                    <a
                        href="#"
                        class="action-icon"
                        @click.stop.prevent="archiveOrRestoreCustomer(item, index)"
                    >
                        <i v-if="!onlyArchived" class="far fa-trash-alt" />
                        <i v-else class="fas fa-trash-restore" />
                    </a>
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import RetentionReport from '@components/customers/RetentionReport';

export default {
    page: {
        title: 'Customers',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        RetentionReport
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Customers',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Customers',
                    active: true
                }
            ],
            labels: [
                'first_name',
                'last_name',
                'email',
                {
                    value: 'orders_count',
                    text: 'Number of orders',
                    type: 'number',
                    sum: true
                },
                {
                    value: 'total_purchase',
                    text: 'Total Purchases',
                    filter: 'variantFixedPrice',
                    type: 'number',
                    sum: true
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'actions',
                    sortable: false,
                    filterable: false
                }
            ],
            customers: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            onlyArchived: false,
            isLoading: false
        };
    },

    watch: {
        onlyArchived() {
            this.pagination.currentPage = 1;
            this.fetchCustomers();
        }
    },

    mounted() {
        this.fetchCustomers();
    },

    methods: {
        ...mapActions({
            getCustomers: 'customers/index',
            archiveCustomer: 'customers/archive',
            restoreCustomer: 'customers/restore'
        }),

        async fetchCustomers() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    onlyArchived: this.onlyArchived,
                    filters: this.filters
                };

                const { rows, count } = await this.getCustomers(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.customers = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        async archiveOrRestoreCustomer(customer, index) {
            const message = `Are you sure you want to ${
                this.onlyArchived ? 'restore' : 'delete'
            } this customer - ${customer.first_name} ${customer.last_name}?`;

            const confirmed = await this.$confirmationModal(message);

            if (!confirmed) {
                return;
            }

            try {
                if (this.onlyArchived) {
                    await this.restoreCustomer(customer.id);

                    this.$toaster('Customer has been restored!');
                } else {
                    await this.archiveCustomer(customer.id);

                    this.$toaster('Customer has been deleted!');
                }

                this.customers.splice(index, 1);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchCustomers();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchCustomers();
        }
    }
};
</script>
