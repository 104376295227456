<template>
    <div>
        <div class="input-group">
            <date-picker
                v-model="dates"
                range
                value-type="YYYY-MM-DD"
                :format="'DD.MM.YYYY'"
                :lang="{
                    formatLocale: {
                        firstDayOfWeek: 1
                    }
                }"
                placeholder="Select date period"
            />
            <span class="btn btn-blue input-group-append">
                <i class="mdi mdi-calendar-range" />
            </span>
            <button
                class="btn btn-blue waves-effect waves-light ml-1"
                style="width: 162px;"
                type="button"
                :disabled="exporting"
                @click="getRetentionReport"
            >
                <i class="fe-download mr-1" />
                {{ exporting ? 'Exporting...' : 'Retention Report' }}
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';

export default {
    components: {
        DatePicker
    },

    data() {
        return {
            dates: [],
            exporting: false
        };
    },

    methods: {
        ...mapActions({
            retentionReportExport: 'orders/retentionReportExport'
        }),

        async getRetentionReport() {
            if (!this.dates.length) {
                this.$toasterWarning(
                    'Please select date period!'
                );

                return;
            }

            this.exporting = true;

            const [startDate, endDate] = this.dates;

            try {
                const csvContent = await this.retentionReportExport({
                    startDate,
                    endDate
                });

                this.prepareCsv('Retention_report', csvContent);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        },

        prepareCsv(fileName, csvContent) {
            const fileURL = window.URL.createObjectURL(new Blob([csvContent]));
            const fileLink = document.createElement('a');

            const date = moment().format('YYYY-MM-DD_HH-mm-ss');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${fileName}_${date}.csv`);
            document.body.appendChild(fileLink);

            fileLink.click();
        }
    }
};
</script>
